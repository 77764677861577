import React from "react";
import styles from "./styles.module.scss";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const PostCard = ({
  image,
  title,
  uri,
  date,
  type,
  background,
  lightBlueText,
  showImage = true,
  extraClass = "",
  customeUrl = null,
}) => {
  let imagePath = image?.node?.localFile?.publicURL;
  let altImage = image?.node?.altText;

  const handleReadMoreClick = () => {
    window.location.href = uri;
  };

  return (
    <AniLink
      paintDrip
      top="entry"
      entryOffset={80}
      hex="#518ef8"
      duration={0.6}
      to={uri}
      style={{ textDecoration: "none" }}
    >
      <div
        className={`${styles.container} ${styles[extraClass]}`}
        style={{ backgroundColor: background }}
      >
        {image && (
          <div className={styles.imageContainer}>
            <div className={styles.overlay}></div>
            {image && (
              <>
                <img src={customeUrl ? customeUrl : imagePath} alt={altImage} />
              </>
            )}
          </div>
        )}
        <div className={styles.text}>
          <p
            className={styles.tag}
            style={{ color: `${lightBlueText ? lightBlueText : ""}` }}
          >
            {type}
          </p>
          {extraClass === "storyCard" ? (
            <h3
              dangerouslySetInnerHTML={{ __html: title?.slice(0, 89) + "..." }}
            />
          ) : (
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          )}
          <p className={styles.date}>{date}</p>
          <button onClick={handleReadMoreClick} className={styles.readMoreButton}>
            Read More
          </button>
        </div>
      </div>
    </AniLink>
  );
};

export default PostCard;
