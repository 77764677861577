import React, { useState } from "react";
import PostCard from "../../atoms/PostCard";
import styles from "./styles.module.scss";
import Button from "../../atoms/seeAllButton/index";

const CardContainer = ({
  setIndexStory,
  background,
  containerBg,
  text,
  color,
  border,
  padding,
  data,
  coursePadding,
  slider,
  sourceUrl=false 
}) => {
  const [renderLimit, setRenderLimit] = useState(3);

  return (
    <div className={styles.cardContainer} style={{ background: containerBg }}>
      <div className={`${styles.wrapper} ${styles[slider]}`}>
        {data.slice(0, renderLimit).map((item, index) => {
          return (
            <PostCard
              setIndexStory={setIndexStory}
              key={index}
              index={index}
              image={item.featuredImage}
              type={item.title}
              title={item.content}
              date={item.date || item.successStory?.profession}
              background={background}
              padding={padding}
              color={color}
              border={border}
              lightBlueText="#15C1FF"
              coursePadding={coursePadding}
              extraClass="storyCard"
              uri={item.uri}
              sourceUrl = {sourceUrl}
              customeUrl={sourceUrl ? item?.featuredImage?.node?.sourceUrl : false}
            />
          );
        })}
      </div>
      <div style={{ textAlign: "center" }}>
        {renderLimit <= data.length && (
          <Button setRenderLimit={setRenderLimit} renderLimit={renderLimit} />
        )}
      </div>
    </div>
  );
};

export default CardContainer;
