import React from "react";
import styles from "./style.module.scss";

const Story = ({ author }) => {
  return (
    <div className={styles.story}>
      <div className={styles.wrapper}>
        <h3 className={styles.storyTitle}>Success Stories</h3>
        <div className={styles.imageContainer}>
          <img src={author.image} alt="learnio"/>
        </div>
        <h5 className={styles.name}>{author.name}</h5>
        <h3 className={styles.profession}>{author.profession}</h3>
        <h5 className={styles.location}>{author.location}</h5>
        <h3 className={styles.mystory}>My Story</h3>
        <p className={styles.info}>{author.story}</p>
      </div>
    </div>
  );
};

export default Story;
