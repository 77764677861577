import React from 'react';
import styles from "./styles.module.scss";

const Title = () => {
  return (
    <div className={styles.title}>
    <h5>Blog</h5>
    <h3>Recent stories</h3>
    </div>
  )
}

export default Title