import React from 'react'
import styles from "./styles.module.scss"

const seeAllButton = ({ setRenderLimit, renderLimit }) => {
  return (
    <button
      onClick={() => setRenderLimit( renderLimit + 3 )}
      className={styles.seeAll}
    >
      {"Load more"}
    </button>
  );
};

export default seeAllButton