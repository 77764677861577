import React from "react";
import styles from "./style.module.scss";
import GoalImage from "../../../assets/images/goal.png";
import Button from "../../atoms/Button"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Goal = ({extraClass , button}) => {
  return (
    <div className={`${styles.goal} ${styles[extraClass]}`}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.content}>
            <h5>Goal</h5>
            <h3>Learnio helps you to succeed</h3>
            <p>
              Hear from our former graduates on their experiences with Learnio
              Academy and where they are now!
            </p>
          {button &&
          <AniLink
          paintDrip
          top="entry"
          entryOffset={80}
          hex="#518ef8"
          duration={0.6}
          to="/blog"
          style={{ textDecoration: "none" }}
        >
        <Button to="/blog" text="See Stories" className={styles.btn}/>
        </AniLink>
          }
          </div>
        </div>
        <div className={styles.right}>
          <img src={GoalImage} />
        </div>
      </div>
    </div>
  );
};

export default Goal;
